import React from "react";
import "./MissionHome.css";

const MissionHome = () => {
  return (
    <>
      <div className="mission-home">
        <div className="mission-home-content container">
          <div className="missionHomeCaption">
            <h1>OUR MISSION</h1>
              <p>
              At Buena Park Rehab, we are dedicated to empowering individuals and families on their journey towards mental well-being and recovery from addiction. With compassion, evidence-based practices, and a commitment to holistic care, we strive to restore hope, foster resilience, and promote lasting healing. Our mission is to provide a safe, inclusive, and supportive environment where every individual can thrive, grow, and reclaim their path to a brighter, healthier future."
              </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionHome;
