import React from 'react';
import './Hero.css';
import DepressedMan from '../../assets/smiling-man.webp'

const Hero = () => {
  return (
<>
    <div className='hero-bg'>
    <div className="landing-page">
        <div className="image-container">
            <img src={DepressedMan} alt="Descriptive Alt Text" />
        </div>
        <div className="text-container">
            <h1>Buena Park Rehab</h1>
            <h2><span>BETTER</span> MENTAL HEALTH <span>STARTS</span> HERE</h2>
            <p>
            At Buena Park Rehab, we specialize in providing comprehensive care for a wide range of mental health conditions and substance abuse addictions. Our expert team addresses issues including depression, anxiety, bipolar disorder, and substance dependencies to support individuals on their path to recovery.
            </p>
        </div>
    </div>
    </div>
</>

  );
}

export default Hero;
