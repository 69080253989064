import React from 'react';
import './ImageGrid.css';
import DepressedMan from '../../assets/depressed_man.webp'
import Depression from '../../assets/depression.webp';
import Bipolar from '../../assets/bipolar.webp';
import Anxiety from '../../assets/anxiety.webp';
import PTSD from '../../assets/PTSD.webp';
import ADHD from '../../assets/ADHD.webp';

import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";



const ImageGrid = () => {
  return (


<>
 <div className='sectionBg'>

            <div className='imageGridHeader'>
                <h1>Mental Health Conditions We Treat</h1>
                <p>Our expert team addresses issues including depression, anxiety, bipolar disorder, and substance dependencies to support individuals on their path to recovery.</p>
            </div>




        <div className='imageGrid'>

{/* ----------- LEFT ----------- */}

            <div className='gridLeft hideMe'>

                <div className='gridItem1'>
                    <div className="gridLeftImage1">
                        <img src={Depression} alt="Separation Anxiety" />
                        <h1>Depression</h1>
                        <p>Persistent feeling of sadness and loss of interest that can significantly interfere with daily life.</p>
                    </div>    
                </div>

                <div className='gridItem2'>
                    <div className="gridLeftImage2">
                        <img src={Anxiety} alt="Anxiety" />
                        <h1>Anxiety</h1>
                        <p>Anxiety is a feeling of worry or fear that can interfere with daily activities.</p>
                    </div>    
                </div>

                <div className='gridItem3'>
                    <div className="gridLeftImage3">
                        <img src={Bipolar} alt="Bipolar" />
                        <h1>Bipolar Disorder</h1>
                        <p>Bipolar disorder is a mental health condition characterized by extreme mood swings, including emotional highs (mania) and lows (depression).</p>
                    </div>    
                </div>

            </div>

{/* ----------- END OF LEFT ----------- */}














{/* ----------- CENTER ----------- */}

            <div className='gridCenter'>
                <img src={DepressedMan} alt='Depressed Man' loading='lazy' /> 
                <div className='gridCenterBtn'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/mental-health">
                            <button>Learn More</button>
                        </Link>
                    </LinkRoll>
                </div>
            </div>

{/* ----------- END OF CENTER ----------- */}








{/* ----------- RIGHT ----------- */}

            <div className='gridRight hideMe'>
                    <div className='gridItem'>
                        <div className="gridRightImage1">
                            <img src={PTSD} alt="PTSD" />
                            <h1>PTSD</h1>
                            <p>PTSD is a mental health condition triggered by experiencing or witnessing a traumatic event, causing severe anxiety, flashbacks, and persistent distressing memories.</p>

                        </div>    
                    </div>

                    <div className='gridItem'>
                        <div className="gridRightImage2">
                            <img src={ADHD} alt="ADHD" />
                            <h1>ADHD</h1>
                            <p>ADHD, or Attention-Deficit/Hyperactivity Disorder, is a neurodevelopmental disorder characterized by persistent patterns of inattention, hyperactivity, and impulsivity.</p>

                        </div>    
                    </div>

                    <div className='gridItem'>
                        <div className="gridRightImage3">
                            <img src={Depression} alt="Separation Anxiety" />
                            <h1>Schizophrenia</h1>
                            <p>Schizophrenia is a chronic mental disorder characterized by distorted thinking, hallucinations, and impaired daily functioning.</p>

                        </div>    
                    </div>            
            </div>

{/* ----------- END OF RIGHT ----------- */}

            
        </div>
    </div>        
</>



  );
};

export default ImageGrid;
